<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="Privatetrafficconstruction" style="width:100vw">
    <!-- <div v-for="imginfo in imgList" :key="imginfo.imgID" style="height:50vh">
        <img :src="imginfo.imgURL"  width="100%" height="100%">
    </div> -->
    <div id="acc5ec99">
      私域流量建设
    </div>
    <div id="a3ac3e3c">
      <div id="c91a44c7"
           v-for="article of articleList"
           :key="article.articleID"
           @click="clk_bfdc84af(article)">
        <img :src="article.articleImg"
             id="c6c3b550" />
        <div id="fed9b6a1">
          {{ article.articleTitle }}
        </div>
        <div id="a77cae98">
          <div id="a1fb8b46">
            {{ article.articleSummary }}
          </div>
        </div>
      </div>
    </div>
    <div id="aa29fe8f">
      <div id="f26f45ef">
        解决方案
      </div>
      <!-- <div class="solution__wrap">
        <div class="solution__item"
             @click="clk_bfdc84af(solutionlist1)">
          <div class="img__wrap">
            <img :src="solutionlist1.articleImg"
                 id="aac1758e" />
          </div>
          <div class="info__wrap">
            <div class="info_title">
              {{solutionlist1.articleTitle}}
            </div>
            <div class="info_content">
              {{articleContent1}}
            </div>
          </div>
        </div>
        <div class="solution__item"
             style="visibility: none;"
             @click="clk_bfdc84af(solutionlist2)">
          <div class="info__wrap">
            <div class="info_title">
              {{solutionlist2.articleTitle}}
            </div>
            <div class="info_content">
              {{articleContent2}}
            </div>
          </div>
          <div class="img__wrap">
            <img :src="solutionlist2.articleImg" />
          </div>
        </div>
      </div>
      <div class="more">
        <button class="btn dal-btn"
                @click="clicka7acc3bb">了解更多</button>
      </div> -->
      <div class="solution__wrap">
        <div class="solution_item left solution_info"
             @click="clk_bfdc84af(solutionlist1)">
          <p>{{solutionlist1.articleTitle}}</p>
          <p class="ellipsis-3">{{solutionlist1.articleSummary}}</p>
          <div class="btn-wrapper">
            <button class="btn dal-btn text-lg">了解详情</button>
          </div>
        </div>
        <div class="solution_item right">
          <img :src="solutionlist1.articleImg"
               @click="clk_bfdc84af(solutionlist1)" />
        </div>
        <!-- <div class="solution_item left solution_info"
             @click="clk_bfdc84af(solutionlist2)">
          <p>{{solutionlist2.articleTitle}}</p>
          <p class="ellipsis-6">{{solutionlist2.articleSummary}}</p>
        </div>
        <div class="solution_item left">
          <img :src="solutionlist2.articleImg"
               @click="clk_bfdc84af(solutionlist1)" />
        </div> -->
      </div>
      <!-- <div class="more">
        <button class="btn dal-btn">了解更多</button>
      </div> -->
    </div>
    <div id="a50ccd27">
      客户案例
    </div>
    <!-- <div id="ae905899">
    <img src="../assets/img-a05b1dbd.png" id="a05b1dbd"/>
    <img src="../assets/img-a5db1b86.png" id="a5db1b86"/>
    <img src="../assets/img-a81abc0b.png" id="a81abc0b"/>
  </div> -->
    <!-- <el-carousel :autoplay="false"
                 type="card"
                 height="30vh">
      <el-carousel-item v-for="article of caseList"
                        :key="article.articleID">
        <div class="medium"><img id="a5db1b86"
               :src="article.articleImg"
               @click="clk_bfdc84af(article)" />
        </div>
      </el-carousel-item>
    </el-carousel> -->
    <CardStackCarousel height="500px">
      <CardStackCarouselItem v-for="article in caseList"
                             :key="article.articleID">
        <div class="example_wrap"
             @click="clk_bfdc84af(article)">
          <img id="a5db1b86"
               :src="article.articleImg" />
          <p>{{article.articleTitle}}</p>
        </div>
      </CardStackCarouselItem>
    </CardStackCarousel>
    <div id="dda365b7">
      <dal-compfloat id="cmpdda365b7">
      </dal-compfloat>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped,
  // DALINEWEB_IMGCENTER_V0X0X1_getImgList,
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
import CardStackCarousel from '@/components/card_swiper/card-stack-carousel.vue'
import CardStackCarouselItem from '@/components/card_swiper/card-stack-carousel-item.vue'
export default {
  filters: {
    //限制文本显示字数,超出部分用...代替
    contentFilter (value) {
      if (!value) return "";
      // console.log('@@@@@@@@@@@@@', value)
      if (value.length > 10) {
        return value.slice(0, 10) + "..";
      }
      return value;
    },
  },
  components: {
    'dal-compfloat': dal_compfloat,
    CardStackCarousel,
    CardStackCarouselItem
  },
  data () {
    return {
      //在此注释下方添加自定义变量
      articleList: [],
      solutionlist1: {},
      solutionlist2: {},
      caseList: [],
      articleContent1: '',
      articleContent2: ''
    }
  },
  mounted () {
    this.refreshPage()
    this.freshsolution()
    this.getCasesdata()
    this.freshsolution2()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    //在此注释下方添加自定义函数或事件
    clk_bfdc84af (article) {
      this.$router.push({
        path: 'article',
        query: {
          articleID: article.articleID,
          articleType: article.articleType
        }
      })
    },
    async refreshPage () {
      let dicInput = {
        records: 6,
        ArticleType: 167,
        sort_rules: "orderID"
      };
      dicInput.status = "0";

      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );

      this.articleList = res.content.resContent
    },
    async freshsolution () {
      let dicInput = {
        records: 2,
        ArticleType: 168,
        sort_rules: "orderID",
      };
      dicInput.status = "0";

      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const solutionlist = res.content.resContent
      console.log(solutionlist);
      if (solutionlist.length > 0) {
        this.solutionlist1 = solutionlist[0]
      }

    },
    async freshsolution2 () {
      let dicInput = {
        records: 2,
        ArticleType: 170,
        sort_rules: "orderID",
      };
      dicInput.status = "0";

      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const solutionlist = res.content.resContent
      if (solutionlist.length > 0) {
        this.solutionlist2 = solutionlist[0]
      }
    },
    contentFilter (value) {
      if (!value) return ''
      if (value.length > 25) {
        return value.slice(0, 25) + "..."
      }
      return value
    },
    async getCasesdata () {
      let dicInput = {
        ArticleType: 171,
        records: 6,
        sort_rules: "orderID"
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const articleList = res.content.resContent
      this.caseList = articleList
    },
  }
}
</script>
<style lang="less" scoped>
#Privatetrafficconstruction {
  background: #fbfbfe;
  font-size: calc(0.5vw + 0.8em);
}
.ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.ellipsis-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#header() {
  .title {
    position: relative;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    text-align: center;
    font: 500 2em/1.5 "PingFang SC";
    font-size: 1.5em;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2em;
      height: 0.15em;
      background: #e74c3c;
    }
  }
}
#Privatetrafficconstruction {
  padding-bottom: 50px;
}

#ca80ddfb {
  width: 100vw;
  height: 50vh;
  position: relative;
  background-image: url("../assets/img-ab664084.png");
  background-size: cover;
}
#acc5ec99 {
  padding-top: 100px;
}
#acc5ec99 {
  #header .title();
}
#a3ac3e3c {
  padding: 0 15vw;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  margin-top: 60px;
}
#a3ac3e3c img {
  border-radius: 1.5em;
}
#c91a44c7:nth-child(n + 4) {
  margin-top: 2em;
}
#a3ac3e3c::after {
  content: "";
  width: 36.57vw;
}
#c91a44c7 {
  width: 30%;
  position: relative;
  /* height: 550px; */
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
#c6c3b550 {
  width: 100%;
  // height: 300px;
  object-fit: cover;
}
#c6c3b550 {
  vertical-align: middle;
}

#fed9b6a1 {
  margin-top: 1em;
  text-align: center;
  color: #000;
  font: bold 1em/1.2 "PingFang SC";
}
#a77cae98 {
  position: relative;
  margin-top: 10px;
}
#a1fb8b46 {
  margin-top: 1em;
  color: #727272;
  // font-size: 18px;
  font: normal 0.75em/1.5 "PingFang SC";
  &:extend(.ellipsis-3);
}
#d800a97f {
  width: 385px;
  position: relative;
  margin-left: 32px;
  display: inline-block;
  vertical-align: middle;
}
#fbc90910 {
  width: 385px;
  height: 273px;
}
#fbc90910 {
  vertical-align: middle;
}
#a055b499 {
  margin-top: 30px;
}
#a055b499 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#cef67ade {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a84ac8db {
  width: 302px;
}
#a84ac8db {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#aeee7856 {
  width: 385px;
  position: relative;
  margin-left: 31px;
  display: inline-block;
  vertical-align: middle;
}
#a1a75a24 {
  width: 385px;
  height: 273px;
}
#a1a75a24 {
  vertical-align: middle;
}
#ab5a7d3d {
  margin-top: 30px;
}
#ab5a7d3d {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#a60442df {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a26e9d89 {
  width: 302px;
}
#a26e9d89 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#a300ceb0 {
  width: 1218px;
  position: relative;
  margin-top: 30px;
  padding-left: 351px;
}
#b9003fc3 {
  width: 385px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
#e29cff34 {
  width: 385px;
  height: 273px;
}
#e29cff34 {
  vertical-align: middle;
}
#a6d03d07 {
  margin-top: 30px;
}
#a6d03d07 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#a80ff6a3 {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#afadb0e4 {
  width: 302px;
}
#afadb0e4 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#c24b5ad4 {
  width: 385px;
  position: relative;
  margin-left: 32px;
  display: inline-block;
  vertical-align: middle;
}
#a2661426 {
  width: 385px;
  height: 273px;
}
#a2661426 {
  vertical-align: middle;
}
#aa3d8ef1 {
  margin-top: 30px;
}
#aa3d8ef1 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#df6dccc3 {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#c11d207d {
  width: 302px;
}
#c11d207d {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#df8fb7a8 {
  width: 385px;
  position: relative;
  margin-left: 31px;
  display: inline-block;
  vertical-align: middle;
}
#ab8fc10e {
  width: 385px;
  height: 273px;
}
#ab8fc10e {
  vertical-align: middle;
}
#a6969477 {
  margin-top: 30px;
}
#a6969477 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#aacc1b02 {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a97e8810 {
  width: 302px;
}
#a97e8810 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#aa29fe8f {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  // height: 100vh;
  position: relative;
  margin-top: 50px;
}
#aa29fe8f {
  // background-image: url("../assets/img-ad347181.jpg");
  // background-size: cover;
  background: #fff;
  color: #000;
}

#f26f45ef {
  #header .title();
  margin-top: 1em;
  font-size: 1.5em;
  color: inherit;
}
#f26f45ef::after {
  // background: #fff;
}

#a15c590d,
#f72530e2 {
  height: 30vh;
}
#a15c590d {
  display: flex;
  justify-content: space-around;
  // padding: 0 15vw;
  position: relative;
  margin-top: 60px;
}
#aac1758e {
  // width: 609px;
  // height: 344px;
  // display: inline-block;
  // vertical-align: middle;
  // object-fit: cover;
}
#aac1758e {
  vertical-align: middle;
}
#a1aff4ad {
  text-align: center;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
#a6568a38 {
}
#a6568a38 {
  text-align: center;
  color: #fff;
  font-size: 45px;
  font-family: PingFang SC;
}
#f5c2d116 {
  position: relative;
  margin-top: 30px;
}
#aeae1e02 {
  width: 468px;
}
#aeae1e02 {
  text-align: left;
  color: #fff;
  font-size: 24px;
  font-family: PingFang SC;
}
#f72530e2 {
  width: 1152px;
  position: relative;
  margin-top: 60px;
  padding-left: 417px;
}
#af8bb597 {
  width: 478px;
  position: relative;
  padding-bottom: 63px;
  display: inline-block;
  vertical-align: middle;
}
#a335f57b {
}
#a335f57b {
  text-align: center;
  color: #fff;
  font-size: 45px;
  font-family: PingFang SC;
}
#a4a31eac {
  width: 478px;
  position: relative;
  margin-top: 30px;
}
#a577c219 {
  width: 478px;
}
#a577c219 {
  text-align: left;
  color: #fff;
  font-size: 24px;
  font-family: PingFang SC;
}
#a9219a93 {
  width: 609px;
  height: 344px;
  margin-left: 65px;
  display: inline-block;
  vertical-align: middle;
}
#a9219a93 {
  vertical-align: middle;
}
#a7acc3bb {
  width: 299px;
  margin-top: 155px;
  margin-left: 812px;
  margin-bottom: 50px;
}
#a7acc3bb {
  background-color: rgb(255, 255, 255, 1);
  border-radius: 10px 10px 10px 10px;
}
#a7acc3bb {
  color: #141620;
  font-size: 40px;
  font-family: PingFang SC;
  border: transparent;
}
#a50ccd27 {
  margin-top: 2em;
  #header .title();
}

#ae905899 {
  width: 1218px;
  position: relative;
  margin-top: 60px;
  padding-left: 351px;
  padding-bottom: 365px;
}
#a05b1dbd {
  width: 68px;
  height: 68px;
  padding-top: 110px;
  padding-bottom: 133px;
  display: inline-block;
  vertical-align: middle;
}
#a05b1dbd {
  vertical-align: middle;
}
.example_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  img {
    width: 25%;
    border-radius: 10px;
  }
  p {
    padding: 1em;
    font-size: 1.2em;
    font-weight: 700;
  }
}
#a5db1b86 {
    height: 200px;
      object-fit: contain;
}
#a81abc0b {
  width: 68px;
  height: 68px;
  margin-left: 159px;
  padding-top: 110px;
  padding-bottom: 133px;
  display: inline-block;
  vertical-align: middle;
}
#a81abc0b {
  vertical-align: middle;
}
#dda365b7 {
  width: 150px;
  position: fixed;
  z-index: 50;
  top: 2908px;
  left: 1740px;
}
#dda365b7 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
.medium {
  height: 30vh;
  display: flex;
  height: 30vh;
  justify-content: center;
  align-items: center;
}

.solution__wrap {
  flex: 1;
  padding: 1em 15vw 4em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2em;
  // column-gap: 4em;
  .solution_info {
    box-sizing: border-box;
    padding: 0 10%;
    width: 100%;
  }
  .solution_info_right {
    // margin-left: 20%;
    box-sizing: border-box;
    padding: 0 10%;
    width: 100%;
  }
  .solution_item {
    font-size: 1.5em;
    color: inherit;
    cursor: pointer;
    p:nth-child(1) {
      font-size: 1.2em;
      // text-align: center;
    }
    p:nth-child(2) {
      margin-top: 1em;
      font-size: 0.75em;
      // text-align: center;
    }
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  img {
    // width: 60%;
    width: 100%;
    border-radius: 0.2em;
    // height: 30vh;
    // object-fit: cover;
  }
}
.btn-wrapper {
  margin-bottom: 3em;
  margin-top: 1.5em;
  button {
    font-size: 0.6em;
    padding: 0.75em 3em;
  }
}
.more {
  display: flex;
  justify-content: center;
  margin-top: 3em;
  padding-bottom: 2em;
  button {
    padding: 0.5em 2em;
    font-size: 1.5em;
    color: #000;
    background: #fff;
  }
}
</style>
